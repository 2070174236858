<template>
    <b-modal id="mark-classification-as-done" size="lg" :title="modalTitle" :ok-title="modalbtnOk" ref="modal"
        @ok="handleOk" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
        :ok-only="isReadonly" :no-close-on-backdrop="true">

        <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

        <b-form @submit.stop.prevent="handleSubmit" novalidate>
            <b-container fluid>

                <b-tabs>
                    <b-tab title="Primary Details" active>
                        <b-row>
                            <b-col lg="6" md="6" sm="12">
                                <b-form-group>
                                    <b>Dispatch No.</b><br>
                                    <span class="numFont">{{ classification.dispatchNo }}</span>
                                </b-form-group>

                                <b-form-group>
                                    <b>Asset Type</b><br>
                                    {{ classification.assetType }}
                                </b-form-group>

                                <b-form-group>
                                    <b>Area</b><br>
                                    <span>
                                        <i class="fa fa-map-marker"></i>&nbsp;
                                        <i>{{ getArea() }}</i>
                                    </span>
                                </b-form-group>

                                <b-form-group>
                                    <b>Remarks</b><br>
                                    {{ classification.remarks }}
                                </b-form-group>

                            </b-col>
                            <b-col lg="6" md="6" sm="12">
                                <b-form-group>
                                    <b>Supporting Documents</b><br>
                                </b-form-group>

                                <b-form-group v-if="document !== null" v-for="(document, i) in classification.documents" :key="i"
                                    :document="document" :label="`Document ${i + 1}`">
                                    <b class="label-document" @click="showImage(document.url)">{{ document.name }}</b>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>

                    <b-tab title="Classification">
                        <b-row class="mt-2 mb-4">
                            <b-col sm="8">
                                <b>Total Assets Classified</b> &nbsp;<span class="numFont">
                                    {{ getTotalClassifiedAssets().toLocaleString() }}
                                </span>
                            </b-col>
                        </b-row>

                        <b-table show-empty striped hover :items="items" :fields="fields" :sort-by.sync="sortBy"
                            :current-page="currentPage" :per-page="perPage" :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection" responsive>

                            <template v-slot:cell(quantity)="row">
                                <span class="numFont">
                                    {{ row.item.quantity.toLocaleString() }}
                                </span>
                            </template>
                        </b-table>

                        <b-row>
                            <b-col md="8" sm="12" class="my-1">
                                <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0
                                    }}</span>
                            </b-col>
                            <b-col md="4" sm="12" class="my-1">
                                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage"
                                    v-model="currentPage" class="my-0" />
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>

                <b-row v-if="isCompleted" class="mt-3">
                    <b-col>
                        <span class="label-info"><i class="icon-info"></i>&nbsp; By clicking proceed, you will no longer
                            be able to edit the details of this classification.
                        </span>
                    </b-col>
                </b-row>
            </b-container>
        </b-form>

    </b-modal>
</template>

<script>
// Utils
import { FileUtil } from '@/utils/fileUtil';
import { DateUtil } from '@/utils/dateutil';

// API
import dispatchApi from '@/api/dispatchApi';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
    name: 'mark-classification-as-done',
    components: {
        Loading
    },
    data() {
        return {
            items: [],
            fields: [
                'condition',
                'handling',
                {
                    key: 'quantity',
                    thClass: 'text-center',
                    tdClass: 'text-right'
                }
            ],

            totalRows: 0,
            currentPage: 1,
            perPage: 5,
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',

            classification: {},
            loggedUser: this.$store.getters.loggedUser,

            // Check for loader
            isLoading: false,
            isCompleted: false,
        }
    },
    computed: {
        modalTitle() {
            return this.isCompleted ? 'Mark Classification as Done' : `${this.classification.assetType} Classification`;
        },
        modalbtnOk() {
            return this.isCompleted ? 'Proceed' : 'OK';
        },
        disableConfirmButtons() {
            return this.isLoading;
        },
        isReadonly() {
            return !this.isCompleted;
        }
    },
    mounted() {
        EventBus.$on('onViewClassificationDetails', (params) => {
            this.onReset(params);
        });
    },
    methods: {
        async handleOk(evt) {
            // Prevent modal from closing
            if (this.isCompleted) {
                evt.preventDefault();
                this.handleSubmit();
            }
        },
        async handleSubmit() {
            // show loading indicator
            this.isLoading = true;

            try {
                this.classification['isCompleted'] = true;
                let { data } = await dispatchApi.saveClassification(
                    this.classification,
                    this.loggedUser.id,
                    DateUtil.getCurrentTimestamp()
                );

                if (data.isSuccess) {
                    this.$toaster.success(`"${this.classification.assetType} classification was marked as done successfully.`);
                    EventBus.$emit('onCloseSaveClassification', data.classification);
                    this.$refs.modal.hide();
                } else {
                    this.$toaster.error(`Error marking "${this.classification.assetType}" classification as done. Please try again.`);
                }
            } catch (error) {
                this.$toaster.error(`Error marking "${this.classification.assetType}" classification as done. Please try again.`);
            }

            // hide loading indicator
            this.isLoading = false;
        },

        showImage(url) {
            let fileName = FileUtil.getUrlFileName(url);
            EventBus.$emit('onSelectImageView', {
                url: url,
                name: fileName
            });
            this.$bvModal.show('image-view-dialog');
        },

        getTotalClassifiedAssets() {
            let total = 0;
            if (!_.isEmpty(this.classification)) {
                _.forEach(this.classification.conditions, condition => {
                    const quantity = condition.quantity;
                    total += parseInt(quantity);
                });
            }
            return total;
        },

        getArea() {
            if (!_.isEmpty(this.classification)) {
                const company = this.classification.area.company;
                const storageLocation = this.classification.area.storageLocation;
                return `${company} - ${storageLocation}`
            }
            return '-';
        },

        onReset(params) {
            this.classification = { ...params.classification };

            // update completion status
            this.isCompleted = params.completed;

            // load the classification conditions to table
            this.items = [];
            const conditions = this.classification.conditions;
            for (const condition of conditions) {
                this.items.push(condition);
            }
            this.totalRows = this.items.length;
        }
    },
    beforeDestroy() {
        EventBus.$off('onViewClassificationDetails');
    }
}

</script>

<style scoped>
.classification-items {
    height: 100px;
    width: 180px;
    color: white;
    border-radius: 5px;
    margin-right: 1em;
}

.item-count {
    font-size: 30px;
}

.item-label {
    font-size: 16px;
}

.vertical-center {
    margin-top: 15px;
    margin-left: 20px;
}

.label-document {
    color: #008036;
    cursor: pointer;
}

.label-info {
    font-style: italic;
    color: #008036;
}
</style>